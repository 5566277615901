class Structure extends Service
  constructor: (Restangular, OldApp, Base, Employment, Permissions, Constants) ->
    return class Structure extends Base
      constructor: (json, @parent) ->
        angular.extend(@, json)

        @permissions = new Permissions(@permissions) if @permissions

        if @children
          @children = json.children.map (child) =>
            Structure.build(child, @)

      @get: (id, options = {}) ->
        Restangular.one('structures', id).get(options).then (data) ->
          Structure.build(data)

      @getPage: (entity_id, descendant_id) ->
        options = { per_page: Constants.structures.PAGE_SIZE }
        Restangular.one("structures", entity_id).one("find_page", descendant_id).get(options).then (response) ->
          response.data

      @getCorporationTree: (id, options = {}) ->
        Restangular.one('structures', id).one('corporation_tree').get(options).then (response) ->
          response.data.map (structure) => Structure.build(structure)

      @structureWithEmptyVacancies: (id) ->
        Restangular.one('structures', id).customGETLIST('vacancies/empty').then (response) =>
          response.data.map (structure) => Structure.build(structure)

      @structureWithDescendantsAndRelatedInfo: (structure_id, options = {}) ->
        @structureWithDecendants(structure_id, options).then (structuresResponse) =>
          structures = structuresResponse.data
          if _.isEmpty structures
            return structuresResponse

          descendantIds = _.map structures, (structure) -> structure.id
          Restangular.one("structures", structure_id).one('details').get({ "descendant_ids[]": descendantIds }).then (detailsResponse) =>
            @_appendDescendantsDetails(structures, detailsResponse)
            # Return the first response object which contains pagination info and data which now includes details as well:
            structuresResponse

      @getGroupsWithDetails: (entityId, options = {}) ->
        Restangular.one('structures', entityId).all('groups').getList(options).then (groupsResponse) =>
          groupsResponse.data = _.map groupsResponse.data, (entity) => new Structure(entity)
          groups = groupsResponse.data
          if _.isEmpty groups
            return groupsResponse

          descendantIds = _.map groups, (group) -> group.id
          Restangular.one("structures", entityId).one('details').get({ "descendant_ids[]": descendantIds }).then (detailsResponse) =>
            @_appendDescendantsDetails(groups, detailsResponse)
            # Return the first response object which contains pagination info and data which now includes details as well:
            groupsResponse

      @_appendDescendantsDetails: (structures, detailsResponse) ->
        groupedDetails = _.groupBy detailsResponse.data, 'entity_id'
        _.each structures, (structure) =>
          details = (groupedDetails[structure.id] || [])[0]
          if details
            structure.statistics = details.statistics
            structure.managers = details.managers
            structure.permissions = details.permissions
          else
            structure.statistics = {}
            structure.managers = []
          structure.managersById = _.groupBy structure.managers, "id"
        structures

      @structureWithDecendants: (structure_id, options = {}) ->
        Restangular.one('structures', structure_id).get(options).then (response) ->
          response

      @employments: (structure_id, options = {}) ->
        Restangular.one('structures', structure_id).one('direct_employments').get(options).then (response) =>
          _.map response.data, (employment) => new Employment(employment)

      @build: (structure, parent) ->
        if structure.type == 'Company' || structure.type == 'Department'
          new Structure(structure, parent)

      addManager: (employment) ->
        Restangular.one('structures', @id).all('managers').post({ employment_id: employment.id })

      removeManager: (employment) ->
        Restangular.one('structures', @id).all('managers').remove({ employment_id: employment.id })

      structureTreeWithoutParent: ->
        if @structure_tree.length > 1
          @structure_tree.slice(1)
        else
          @structure_tree

      relativeStructureTreeWithoutParentWithin: (anotherStructure) ->
        relative = @relativeStructureTreeWithin(anotherStructure)
        if relative.length > 1
          relative.slice(1)
        else
          relative

      relativeStructureTreeWithin: (anotherStructure) ->
        index = @structure_tree.indexOf(anotherStructure.name)
        if index
          @structure_tree.slice(index)
        else
          @structure_tree

      withAncestors: ->
        list = []
        if @parent
          list.push @parent.withAncestors()
          list.push @
        list.flatten()

      withDescendants: ->
        [@].concat _.map @children, (child) -> child.withDescendants()
          .flatten()

      snakeCaseType: ->
        _.snakeCase(@type)
