angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'structures.groups',
        url: '/groups'
        params:
          page: 1,
          filters: {},
          focusedGroup: null
        resolve:
          parentEntity: ($stateParams, Structure) ->
            Structure.structureWithDecendants($stateParams.structure_id, { page: 1, per_page: 1 }).then (resp) ->
              new Structure(resp.data[0])
          response: (parentEntity, $stateParams, Structure, Constants) ->
            options = { page: $stateParams.page, per_page: Constants.structures.PAGE_SIZE }
            angular.extend options, $stateParams.filters
            Structure.getGroupsWithDetails(parentEntity.id, options)
        views:
          '@':
            templateUrl: "structures/groups/list.html"
            controller: "StructuresGroupListCtrl"
            controllerAs: 'ctrl'

class StructuresGroupListCtrl extends Controller
  constructor: (@$stateParams, @Paging, @Structure, @Constants, TableOptions, @parentEntity, response) ->
    @focusedGroup = @$stateParams.focusedGroup
    @_buildPages(response)
    @tableOptions = new TableOptions(translationScope: 'groups')

    @filterSettings = [
      {
        translation: "groups.group_name",
        key: "structure_name"
      },
      {
        translation: "groups.member_name",
        key: "person_name",
      },
      {
        translation: "vacancy.role_name",
        key: "position"
      }
    ]

    @filters = @$stateParams.filters

  changePage: (page) ->
    @paging.changePage(page)
    if _.isUndefined(@groups[@_getPageIterator()])
      @_fetchGroups().then (response) =>
        @_addGroupsToPage(response)

  filterGroups: () ->
    @focusedGroup = null
    @_normalizeFilters()
    @paging.changePage(1)
    @_fetchGroups().then (response) =>
      @_buildPages(response)

  _normalizeFilters: () ->
    _.each @filters, (value, key) =>
      if _.isEmpty(value)
        delete @filters[key]

  _buildPages: (response) ->
    @paging = new @Paging(response)
    @groups = new Array(@paging.getTotalPages())
    @_addGroupsToPage(response)

  _fetchGroups: () ->
    options = { page: @paging.page, per_page: @Constants.structures.PAGE_SIZE }
    angular.extend options, @filters
    @Structure.getGroupsWithDetails @parentEntity.id, options

  _addGroupsToPage: (response) ->
    page = @_getPageIterator()
    @groups[page] = _.map response.data, (rawGroup) =>
      group = new @Structure(rawGroup)
      group.actions = @_composeActions(group)
      group.actionsAvailable = !_.isEmpty(group.actions)
      group

  _composeActions: (group) ->
    actions = {}
    if @parentEntity.permissions.one('allowingToManageVacancies')
      if group.type == 'ProfessionalGroup'
        actions.addVacancy = {}
      actions.edit = {}
      actions.editPermissions = {}
    actions

  _getPageIterator: () ->
    @paging.iterator(@paging.page)
