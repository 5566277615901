class StructuresGroupCtrl extends Controller
  constructor: (@$rootScope, @$scope, @$state, @$translate, @$modal, @toastr, @StructureRepository, @SurveyRepository, @Structure, @Constants, @Paging, @Restangular, @Vacancy) ->
    @parentEntity = @$scope.$parent.ctrl.parentEntity
    @focusedGroup = @$scope.$parent.ctrl.focusedGroup
    @isMemberInfoVisible = false
    @memberPagesLoaded = false
    @memberPages = []
    @usePagination = false

  init: (group) ->
    @group = group
    if @focusedGroup && @group.id == @focusedGroup.id
      @getMemberInfo()

  displayManagerNames: (group) ->
    names = _.map group.managers, "name"
    names.join(", ")

  areVacanciesApplicable: (group) ->
    group.type == 'ProfessionalGroup'

  changePage: (page) ->
    @paging.changePage(page)
    index = @paging.iterator(@paging.page)
    if _.isUndefined(@memberPages[index])
      options = { page: @paging.page, per_page: @Constants.structures.PAGE_SIZE }
      if @group.type == 'ProfessionalGroup'
        @_fetchProfessionalGroupVacancies(options).then (response) =>
          @_addProfessionalGroupMemberPage(response)
      else if @group.type == 'ActivityGroup'
        @_fetchActivityGroupAffiliations(options).then (response) =>
          @_addActivityGroupMemberPage(response)

  getMemberInfo: () ->
    @isMemberInfoVisible = !@isMemberInfoVisible
    if !@memberPagesLoaded
      @_buildMemberPages().then =>
        @usePagination = @memberPages.length > 1
        @memberPagesLoaded = true

  startVacancyCreationFlow: () =>
    returnStateName = 'structures.groups'
    returnStateParams = @paramsForCurrentState()
    @$modal.open
      windowClass: "small"
      templateUrl: "structures/groups/vacancies/new.html"
      controller: "NewStructuresGroupVacancyCtrl"
      controllerAs: 'newVacancyCtrl'
      resolve:
        entities: => @_fetchAvailableEmploymentEntities()
        professionalGroup: => @group
        returnStateName: => returnStateName
        returnStateParams: => returnStateParams

  _fetchAvailableEmploymentEntities: () =>
    @Structure.structureWithDecendants(@group.entity_id, { exclude_sub_companies: true, paginate: false }).then (resp) =>
      resp.data = _.map resp.data, (entity) => new @Structure(entity)
      resp.data

  startVacancyDeletionFlow: (vacancy) =>
    @Vacancy.getDeletableRoles(vacancy.id).then (roles) =>
      if _.isEmpty(roles)
        @_openVacancyDeleteModal(vacancy, roles, { "windowClass": "small", "templateUrl": "vacancy/delete/vacancyDelete.html" })
      else
        @_openVacancyDeleteModal(vacancy, roles, { "windowClass": "medium", "templateUrl": "vacancy/delete/vacancyDeleteWithRoles.html" })

  _openVacancyDeleteModal: (vacancy, roles, config) =>
    @$modal.open
      windowClass: config["windowClass"]
      templateUrl: config["templateUrl"]
      controller: "VacancyDeleteModalCtrl"
      controllerAs: 'vacancyModalCtrl'
      resolve:
        vacancy: => @Restangular.restangularizeElement(null, vacancy, 'vacancies')
        roles: => roles
    .result.then =>
      @_reloadCurrentState().then =>
        @toastr.success @$translate.instant('vacancy.deleted')

  createSurveyDraftAndRedirect: (employment_id, survey_type) =>
    @toastr.success "", @$translate.instant('general.starts')
    @SurveyRepository.createSurveyDraft(employment_id, survey_type).then (response) =>
      @$state.go('survey', { id: response.data.id }, { reload: true }).then () =>
        @toastr.success "", @$translate.instant('general.started')

  sendAffiliationReminderFor: (affiliation) ->
    @Restangular.one('affiliations', affiliation.id).all('reminders').post().then (response) =>
      @toastr.success @$translate.instant('general.reminder_sent')

  _buildMemberPages: () ->
    if @group.type == 'ProfessionalGroup'
      @_buildProfessionalGroupMemberPages()
    else if @group.type == 'ActivityGroup'
      @_buildActivityGroupMemberPages()

  _buildProfessionalGroupMemberPages: () ->
    @_fetchProfessionalGroupVacancies({ page: 1, per_page: @Constants.structures.PAGE_SIZE }).then (response) =>
      @_interpretPagingHeaders(response)
      @_addProfessionalGroupMemberPage(response) if response.data.length
      @memberPages

  _fetchProfessionalGroupVacancies: (options = {}) ->
    @StructureRepository.getProfessionalGroupVacancies(@parentEntity.id, @group.id, options)

  _addProfessionalGroupMemberPage: (response) ->
    index = @paging.iterator(@paging.page)
    @memberPages[index] = _.map response.data, (vacancy) =>
      employment = vacancy.employment
      mapped = {
        person: employment && employment.person,
        member_since: employment && employment.employed_at,
        member_until: employment && employment.employed_until,
        status: employment && employment.status,
        role_name: vacancy.role_name,
        structure_unit: vacancy.entity.relativeStructureTreeWithoutParentWithin(@parentEntity).join(' » '),
        vacancy: vacancy,
        employment: employment,
        affiliation: employment,
        vacancy_id: vacancy.id,
        affiliation_id: employment && employment.id,
        actions: @_composeProfessionalGroupMemberActions(vacancy)
      }
      mapped.actionsAvailable = !_.isEmpty(mapped.actions)
      mapped

  _composeProfessionalGroupMemberActions: (vacancy) ->
    actions = {}
    if @parentEntity.permissions.one('allowingToManageVacancies')
      actions.editVacancy = {}
      actions.deleteVacancy = {}
      actions.transferVacancy = {}
      @_appendSurveyActions(actions, vacancy.employment)
      @_appendAffiliationReminderAction(actions, vacancy.employment)
    actions

  _buildActivityGroupMemberPages: () ->
    @_fetchActivityGroupAffiliations({ page: 1, per_page: @Constants.structures.PAGE_SIZE }).then (response) =>
      @_interpretPagingHeaders(response)
      @_addActivityGroupMemberPage(response) if response.data.length
      @memberPages

  _fetchActivityGroupAffiliations: (options = {}) ->
    @StructureRepository.getActivityGroupAffiliations(@parentEntity.id, @group.id, options)

  _addActivityGroupMemberPage: (response) ->
    index = @paging.iterator(@paging.page)
    @memberPages[index] = _.map response.data, (affiliation) =>
      mapped = {
        person: affiliation.person,
        member_since: affiliation.employed_at,
        member_until: affiliation.employed_until,
        status: affiliation.status,
        role_name: null,
        structure_unit: null,
        vacancy: null,
        employment: null,
        affiliation: affiliation,
        vacancy_id: null,
        affiliation_id: affiliation.id,
        actions: @_composeActivityGroupMemberActions(affiliation)
      }
      mapped.actionsAvailable = !_.isEmpty(mapped.actions)
      mapped

  _composeActivityGroupMemberActions: (affiliation) ->
    actions = {}
    if @parentEntity.permissions.one('allowingToManageVacancies')
      @_appendAffiliationReminderAction(actions, affiliation)
    actions

  _appendSurveyActions: (actions, employment) ->
    if employment
      actions.startFeedback = {}
      actions.startDiscussion = {}
      if @$rootScope.currentPerson.currentCompany.id == 1911
        actions.startDiscussion.translationKey = 'general.start_appraisal_interview.sorainen'
      else
        actions.startDiscussion.translationKey = 'general.start_appraisal_interview.default'

  _appendAffiliationReminderAction: (actions, affiliation) ->
    status = affiliation && affiliation.status
    if status == "pending" || status == "standing"
      actions.sendAffiliationReminder = {}

  _interpretPagingHeaders: (response) ->
    @paging = new @Paging(response)
    @memberPages = new Array(@paging.getTotalPages())

  _reloadCurrentState: () ->
    @$state.go(
      "structures.groups"
      @paramsForCurrentState(),
      { reload: true }
    )

  paramsForCurrentState: () ->
    {
      structure_type: 'companies', structure_id: @parentEntity.id,
      page: @$scope.$parent.ctrl.paging.page, focusedGroup: @group, filters: @$scope.$parent.ctrl.filters
    }
