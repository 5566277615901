class VacancyTransferConfig extends Config
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'vacancy.transfer',
        url: '/transfer'
        params:
          returnStateName: null,
          returnStateParams: null
        views:
          '@':
            templateUrl: 'vacancy/transfer/vacancyTransfer.html'
            controller: 'VacancyTransferRouteCtrl'
            controllerAs: 'routeCtrl'
            resolve:
              structures: (currentPerson, Structure) ->
                Structure.getCorporationTree(currentPerson.currentCompany.id)

class VacancyTransferRouteCtrl extends Controller
  constructor: (@$state, @$stateParams, @toastr, @$translate, @vacancy, @structures, @Vacancy, @currentPerson, $rootScope) ->
    @selectedStructure = _.find @structures, (structure) => structure.id == @vacancy.entity_id
    @params = $rootScope.fromParams

  transferTo: =>
    @Vacancy.transfer(@vacancy, @selectedStructure).then =>
      @backToStructure()
      @toastr.success "", @$translate.instant('vacancy.transferred')

  backToStructure: =>
    if _.isEmpty @params
      @params = {structure_type: 'companies', structure_id: @currentPerson.currentCompany.id}
    returnStateName = @$stateParams.returnStateName || 'structures.people'
    returnStateParams = @$stateParams.returnStateParams || {
      structure_id: @params.structure_id, structure_type: @params.structure_type
    }
    @$state.go(returnStateName, returnStateParams)
