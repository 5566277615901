class StructureRepository extends Service

  constructor: (@Restangular, @AffiliatableRole, @Structure) ->

  getManagedStructuresWithDescendants: (person, entity) ->
    @Restangular.one('people', person).one('structures', entity).getList()

  getPeople: (structure, options = {}) ->
    @Restangular.one('structures', structure).all('people').getList(options)

  getEmployments: (structure) ->
    @Restangular.one('structures', structure).all('employments').getList()

  getAllManagers: (structure, options = {}) ->
    @Restangular.one('structures', structure).all('affiliatable_roles').getList(options).then (response) =>
      _.map(response.data, (role) => new @AffiliatableRole(role))

  getAllProfessionalGroups: (company_id) ->
    @Restangular.one('structures', company_id).all('job_families').getList()

  structureWithDecendantsAsArray: (structure_id, options = {}) ->
    @Restangular.one('structures', structure_id).get(options).then (response) =>
      _.map response.data, (entity) => new @Structure(entity)

  structureWithDecendantsAsArrayMultiple: (structure_ids, options = {}) ->
    promises = []
    for id in structure_ids
      promises.push(
        @structureWithDecendantsAsArray(id, options)
      )
    # Since Promise.all(promises) is not IE compatible, we use this.
    $.when.apply($, promises).then((data) ->
      result = []
      for subarray in data
        result = result.concat(subarray)
      return _.uniq(result, 'id')
    )

  getAllProfessionalGroupsAsArray: (company_id) ->
    result = []
    @Restangular.one('structures', company_id).all('job_families').getList().then (response) ->
      _.map response.data, (family) -> result.push {id: family.id, name: family.name}
    result

  allPlanningCategoriesForStructure: (entity_id) ->
    @Restangular.one('structures', entity_id).all('planning_categories').getList()

  allPlanningItemsForStructure: (entity_id) ->
    @Restangular.one('structures', entity_id).all('planning_items').getList()

  getProjects: (entity_id, options = {}) ->
    @Restangular.one('structures', entity_id).all('projects').getList(options).then (response) =>
      _.sortBy(
        _.map(response.data, (project) => new @Structure(project)),
        'structure_tree'
      )

  getGroupsWithDetails: (entityId, options = {}) ->
    @Restangular.one('structures', entityId).all('groups').getList(options).then (response) =>
      response.data = _.map response.data, (entity) => new @Structure(entity)
      response

  getProfessionalGroupVacancies: (entityId, professionalGroupId, options = {}) ->
    @Restangular.one('structures', entityId).one('professional_groups', professionalGroupId).all('vacancies').getList(options).then (response) =>
      _.each response.data, (vacancy) =>
        vacancy.entity = new @Structure(vacancy.entity)
      response

  getActivityGroupAffiliations: (entityId, activityGroupId, options = {}) ->
    @Restangular.one('structures', entityId).one('activity_groups', activityGroupId).all('affiliations').getList(options)
