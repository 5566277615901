class NewStructuresGroupVacancyCtrl extends Controller
  constructor: (@$modalInstance, @$state, @Restangular, @entities, @professionalGroup, @returnStateName, @returnStateParams) ->

  create: ->
    return unless @entity && @professionalGroup
    @Restangular.one('structures', @entity.id).all('vacancies').post({ professional_group_ids: [@professionalGroup.id] }).then (response) =>
      @$modalInstance.close()
      @$state.go(
        'vacancy.edit',
        { vacancy_id: response.data.id, returnStateName: @returnStateName, returnStateParams: @returnStateParams },
        { reload: true }
      )

  cancel: ->
    @$modalInstance.dismiss("dismissed")
