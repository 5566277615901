class TopNavigationCtrl extends Controller
  constructor: (@$scope, @Constants, @$rootScope, @$translate, @$state, @CurrentCompanyService, @CurrentPerson, @$window, LanguageService) ->
    @service = LanguageService
    @$rootScope.isInfoDropdownVisible = false
    @$rootScope.isLanguageDropdownVisible = false
    @$rootScope.isSmallTopDropdownVisible = false
    @service.setUserLanguage()

    window.onclick = =>
      if @$rootScope.isInfoDropdownVisible
        @$rootScope.isInfoDropdownVisible = false
        @$scope.$apply()
      if @$rootScope.isLanguageDropdownVisible
        @$rootScope.isLanguageDropdownVisible = false
        @$scope.$apply()
      if @$rootScope.isSmallTopDropdownVisible
        @$rootScope.isSmallTopDropdownVisible = false
        @$scope.$apply()

  openInfoDropdown: (event) ->
    @$rootScope.isInfoDropdownVisible = !@$rootScope.isInfoDropdownVisible
    event.stopPropagation()

  openLanguageDropdown: (event) ->
    @$rootScope.isLanguageDropdownVisible = !@$rootScope.isLanguageDropdownVisible
    event.stopPropagation()

  openSmallTopDropdown: (event) ->
    @$rootScope.isSmallTopDropdownVisible = !@$rootScope.isSmallTopDropdownVisible
    event.stopPropagation()

  helpLink: ->
    @Constants.helpLinks[@$translate.use()] || @Constants.helpLinks.fallback

  changeCompany: (company_id) =>
    @CurrentCompanyService.setCurrentCompany(company_id).then =>
      @CurrentPerson.fetch().then (person) =>
        @$window.location.href = @_getCurrentStateHref(person)

  _getCurrentStateHref: (person) ->
    if _.includes ['structures.people', 'structures.groups'], @$state.current.name
      @$state.href @$state.current.name, { structure_id: person.currentCompany.id, structure_type: 'companies', page: null }
    else
      @$state.href @$state.current.name, {}

  changeVacancy: (company_id, vacancy_id) ->
    @CurrentCompanyService.setCurrentCompany(company_id, vacancy_id).then =>
      @CurrentPerson.fetch().then (person) =>
        @$rootScope.currentPerson = person
        @$window.location.reload()

